import React from "react";
import { templateStrings } from "../../../../../Constant";
import * as moment from "moment";

const conditionCheck = (props) => {
  if(props.label == 'cameras')return false;
  if(props.label === 'vehicleDispatchConfigDto') return false;
  if (props.value != null && props.label != "applications") {
    if (typeof props.value == "string" && props.value.length === 0) {
      return false;
    } else {
      return true;
    }
  } else if (props.label == "applications" && props.value.length > 0) {
    return true;
  } else {
    return false;
  }
};
const FilterCameras=(val)=>{
  var cameraIds = val.map(function(item) {
      return item['cameraId'];
  });
  return cameraIds.toString();
}
const getValue = (label, value) => {
  switch (label) {
    case 'registrationDate':
      return moment(value).format('DD MMM YYYY');
    case 'applications':
      return value.map((app, index) => <span key={index}>{app.name}{index < value.length - 1 ? ',' : ''}</span>);
    case 'status':
      return value ? 'Active' : 'InActive';
    case 'fuelPlan':
    case 'gpsRequiredFromEtim':
    case 'fwdToShuttl':
      return value ? 'Yes' : 'No';
    case 'tag':
      return value && value.tag ? value.tag : '';
    case 'cameras':
      return value && value.length ? FilterCameras(value) : 'NA';
    default:
      return value.name || value;
  }
};
const getDispatchValues = (value,routeNameCorrespondeToRouteId) => {
  if (!value) return null;
  const { dispatchType, routeId, serviceStartTime, serviceEndTime } = value;
  return (
    <>
      {dispatchType && <div>
        <span className="info-label"> Dispatch Type :</span>
        <span className="info-value">{dispatchType}</span>
      </div>}
      {routeId && <div>
        <span className="info-label"> Route Name :</span>
        <span className="info-value">{routeNameCorrespondeToRouteId}</span>
      </div>}
      {serviceStartTime && <div>
        <span className="info-label"> Service Start Time :</span>
        <span className="info-value">{serviceStartTime}</span>
      </div>}
      {serviceEndTime && <div>
        <span className="info-label"> Service End Time :</span>
        <span className="info-value">{serviceEndTime}</span>
      </div>}
    </>
  );
};

export const VehicleInfoItem = ({ label, value, routeNameCorrespondeToRouteId }) => {
  return (
    <div className="row">
      {conditionCheck({ label, value }) && (
        <div>
          <span className="info-label">{templateStrings[label]} :</span>
          <span className="info-value">{getValue(label,value)}</span>
        </div>
      )}
      {label === 'vehicleDispatchConfigDto' && getDispatchValues(value,routeNameCorrespondeToRouteId)}
    </div>
  );
};
