import { GET_REPORTS, BASE_URL,
    GET_REPORTS_DISTANCE,EXPORT_DISTANCE, EXPORT_GPS_QUALITY,EXPORT_RESTORE_DATA,GET_VEHICLES_LIST,BASE_GPS_MATRIX_STAG_URL} from "../../../../Constant";
import {reportsType} from "../utils";
import {getAPIPromise,getValidToken} from "../../../../services/APIutils";
import FileDownLoader from '../../../../services/fileDownloader';
import {EpochTimezone,getLocalStorageTz} from "../../../../shared/utils";

export function fetchGpsQuality(regionId, type, date) {
    let url;
    var timeZone=localStorage.getItem('timeZone')
    if(type == "distance" || type == 'gpsQuality'){
        if (type == reportsType.DISTANCE) {
            url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REPORTS_DISTANCE}/${type}`
        } else {
            url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REPORTS}/${type}`
        }
        if (regionId) {
            url = `${url}?regionId=${regionId}&date=${EpochTimezone(date,timeZone)}&page=0&pageSize=10000`
        }
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}
export function fetchFrequency(deviceId,fromDate,endDate,regionId){
    let url;
    var timeZone=localStorage.getItem('timeZone')
    url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REPORTS}/frequency?deviceId=${deviceId}&fromDate=${EpochTimezone(fromDate,timeZone)}&toDate=${EpochTimezone(endDate,timeZone)}&regionId=${regionId}`
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchFrequencyByRegion(regionId,date,pageNo,key,searchKey,order,pageSIze = 10){
    let url;
    var timeZone=localStorage.getItem('timeZone')
    url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REPORTS}/frequency/byRegion?regionId=${regionId}&date=${EpochTimezone(date,timeZone)}&page=${pageNo}&pageSize=${pageSIze}&sortKey=${key}&order=${order}`
    if(searchKey){
        url = `${url}&searchKey=${searchKey}`
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchHealthReport(fromDate,endDate,regionId,selectedProtocol,pageNo,search,filterStatus,sortKey,sortOrder, distance, frequency, avgDelay, gpspoint, futurepoint, historypoint, masterType, min90thPercentileDelay){
    let url;
    const timeZone =getLocalStorageTz();
    url = `${BASE_GPS_MATRIX_STAG_URL}api/health?fromDate=${EpochTimezone(fromDate,timeZone)}&toDate=${EpochTimezone(endDate,timeZone)}&regionId=${regionId}&pageNo=${pageNo}&pageSize=10&key=${search}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
    if(filterStatus){
        url = `${url}&health=${filterStatus}`;
    }
    if(selectedProtocol){
        url = `${url}&protocol=${selectedProtocol}`
    }
    if(distance && frequency && avgDelay && gpspoint && futurepoint && historypoint && min90thPercentileDelay){
        url = `${url}&MinDistance=${distance}&MinFreqPercentage=${frequency}&MinPoints=${gpspoint}&MinAvgDelay=${avgDelay}&futureDatePointPercent=${futurepoint}&pastDatePointPercent=${historypoint}&min90thPercentileDelay=${min90thPercentileDelay}`
    }
    if (masterType) {
        if (masterType.cityId) { url = `${url}&city=${masterType.cityId}` }
        if (masterType.agencyId) { url = `${url}&agency=${masterType.agencyId}` }
        if (masterType.operatorId) { url = `${url}&operator=${masterType.operatorId}` }
        if (masterType.depotId) { url = `${url}&depot=${masterType.depotId}` }
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchHaltReport(fromDate,endDate,regionId,pageNo,search,selectedProtocol,masterType){
    let url;
    var timeZone=localStorage.getItem('timeZone')
    url = `${BASE_GPS_MATRIX_STAG_URL}api/haltDevice?fromDate=${EpochTimezone(fromDate,timeZone)}&toDate=${EpochTimezone(endDate,timeZone)}&regionId=${regionId}&pageNo=${pageNo}&pageSize=10&searchKey=${search}`
    if(selectedProtocol){
        url = `${url}&protocol=${selectedProtocol}`
    }
    if (masterType) {
        if (masterType.cityId) { url = `${url}&city=${masterType.cityId}` }
        if (masterType.agencyId) { url = `${url}&agency=${masterType.agencyId}` }
        if (masterType.operatorId) { url = `${url}&operator=${masterType.operatorId}` }
        if (masterType.depotId) { url = `${url}&depot=${masterType.depotId}` }
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function exportHaltReportData(fromDate,endDate,regionId,pageNo,search,selectedProtocol,masterType){
    let url;
    var timeZone=localStorage.getItem('timeZone')
    url = `${BASE_GPS_MATRIX_STAG_URL}api/haltDevice/export?fromDate=${EpochTimezone(fromDate,timeZone)}&toDate=${EpochTimezone(endDate,timeZone)}&regionId=${regionId}&pageNo=${pageNo}&pageSize=10&searchKey=${search}`
    if(selectedProtocol){
        url = `${url}&protocol=${selectedProtocol}`
    }
    if (masterType) {
        if (masterType.cityId) { url = `${url}&city=${masterType.cityId}` }
        if (masterType.agencyId) { url = `${url}&agency=${masterType.agencyId}` }
        if (masterType.operatorId) { url = `${url}&operator=${masterType.operatorId}` }
        if (masterType.depotId) { url = `${url}&depot=${masterType.depotId}` }
    }
    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: {Authorization: 'Bearer ' + token}
        });
    })
}

export function exportHealthReportData(fromDate,endDate,regionId,selectedProtocol,pageNo,search,filterStatus,sortKey,sortOrder, distance, frequency, avgDelay, gpspoint, futurepoint, historypoint, masterType,min90thPercentileDelay) {
    let url;
    const timeZone =getLocalStorageTz();
    url = `${BASE_GPS_MATRIX_STAG_URL}api/health/export?fromDate=${EpochTimezone(fromDate,timeZone)}&toDate=${EpochTimezone(endDate,timeZone)}&regionId=${regionId}&pageNo=${pageNo}&pageSize=10&key=${search}&sortKey=${sortKey}&sortOrder=${sortOrder}&timeZone=${timeZone}`
    if(selectedProtocol){
        url = `${url}&protocol=${selectedProtocol}`
    }
    if(filterStatus){
        url = `${url}&health=${filterStatus}`;
    }
    if(distance && frequency && avgDelay && gpspoint && futurepoint && historypoint && min90thPercentileDelay){
        url = `${url}&MinDistance=${distance}&MinFreqPercentage=${frequency}&MinPoints=${gpspoint}&MinAvgDelay=${avgDelay}&futureDatePointPercent=${futurepoint}&pastDatePointPercent=${historypoint}&min90thPercentileDelay=${min90thPercentileDelay}`
    }
    if (masterType) {
        if (masterType.cityId) { url = `${url}&city=${masterType.cityId}` }
        if (masterType.agencyId) { url = `${url}&agency=${masterType.agencyId}` }
        if (masterType.operatorId) { url = `${url}&operator=${masterType.operatorId}` }
        if (masterType.depotId) { url = `${url}&depot=${masterType.depotId}` }
    }
    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: {Authorization: 'Bearer ' + token}
        });
    })
}

export function fetchRSU(pageNo,searchKey,sortOrder){
    let url;
    url = `${BASE_URL}api/rsu/getRSU?pageNo=${pageNo}&pageSize=10`
    if(searchKey){
        url = `${url}&key=${searchKey}`
    }
    if(sortOrder){
        url = `${url}&order=${sortOrder}`
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function createCCO(deviceId){
    let url;
    url = `${BASE_URL}api/rsu/createCCO?deviceId=${deviceId}`
    return getAPIPromise(url, {
        method: 'post'
    });
}

export function fetchCcoStatus(SRId){
    let url;
    var timeZone=localStorage.getItem('timeZone')
    url = `${BASE_URL}api/rsu/getCcoStatus/${SRId}?timeZone=${timeZone}`
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchSMSStatus(pageNo,searchKey,status,service,startDate,endDate,sortOrder,sortKey){
    let url;
    url = `${BASE_URL}api/sms/details?pageNo=${pageNo}&pageSize=10&key=${searchKey}`
    if(status !== 'All'){
        url = `${url}&status=${status}`
    }
    if(service !== 'All'){
        url = `${url}&provider=${service}`
    }
    if(startDate && endDate){
        url = `${url}&fromDate=${startDate}&toDate=${endDate}`
    }
    if(sortOrder && sortKey){
        url = `${url}&sortOrder=${sortOrder}&sortKey=${sortKey}`  
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function exportSMSReportData(searchKey,status,service,startDate,endDate,sortOrder,sortKey) {
    let url;
    var timeZone=localStorage.getItem('timeZone')
    url = `${BASE_URL}api/sms/export?key=${searchKey}&timeZone=${timeZone}`
    if(status !== 'All'){
        url = `${url}&status=${status}`
    }
    if(service !== 'All'){
        url = `${url}&provider=${service}`
    }
    if(startDate && endDate){
        url = `${url}&fromDate=${EpochTimezone(startDate,timeZone)}&toDate=${EpochTimezone(endDate,timeZone)}`
    }
    if(sortOrder && sortKey){
        url = `${url}&sortOrder=${sortOrder}&sortKey=${sortKey}`  
    }
    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: {Authorization: 'Bearer ' + token}
        });
    })
}


export function fetchDistance(regionId, type, date) {
    var timeZone=localStorage.getItem('timeZone')
    let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REPORTS_DISTANCE}${type}`;
    if (regionId) {
        url = `${url}?regionId=${regionId}&date=${EpochTimezone(date,timeZone)}&page=0&pageSize=10000`
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchVehicleDetail(regionId, type, fromDate, endDate, deviceId) {
    let url;
    var timeZone=localStorage.getItem('timeZone')
    if(type !="deviceFrequency"){
        if (type == reportsType.DISTANCE) {
            url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REPORTS_DISTANCE}/${type}/getDistanceDatewise`
        } else {
            url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REPORTS}/${type}/getByDeviceId`
        }
        if (regionId) {
            url = `${url}?regionId=${regionId}&deviceId=${deviceId}&fromDate=${EpochTimezone(fromDate,timeZone)}&toDate=${EpochTimezone(endDate,timeZone)}`
        }
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchDepotReport(region,city,pageNo,searchKey,startTime,endTime,sortOrder,sortKey){
    let url;
    var timeZone=localStorage.getItem('timeZone')
    url = `${BASE_GPS_MATRIX_STAG_URL}api/geofence/getInOutVehicles/${region}?pageNo=${pageNo}&pageSize=10&key=${searchKey}&timeZone=${timeZone}`;
    if(startTime && endTime) url=`${url}&from=${EpochTimezone(startTime,timeZone)}&to=${EpochTimezone(endTime,timeZone)}`;
    if(sortOrder && sortKey) url = `${url}&sortKey=${sortKey}&order=${sortOrder}`;
    if(city) url = `${url}&cityName=${city}`;
    return getAPIPromise(url, {
        method: 'get'
    });   
}


export function fetchDepotNames(depot){
    let url;
    url = `${BASE_URL}api/master/depot?pageNo=0&pageSize=1000&cityId=${depot}`
    return getAPIPromise(url, {
        method: 'get'
    });   
}

export function cityByRegion(regionId){
    let url;
    url = `${BASE_GPS_MATRIX_STAG_URL}api/region/${regionId}`
    return getAPIPromise(url, {
        method: 'get'
    }); 
}
export function fetchCommandTypes(){
    const url=`${BASE_URL}api/device/config/v2/command-types`;
    return getAPIPromise(url, {
        method: 'get',
    });
}
export function fetchDepotbyCity(city){
    let url;
    url = `${BASE_URL}api/master/depot/${city}`
    return getAPIPromise(url, {
        method: 'get'
    });   
}

export function downLoadDistanceData(exportKey, regionId, fromDate,toDate) {
    const timeZone =getLocalStorageTz();
    if(fromDate && typeof fromDate == 'object'){
        fromDate = parseInt(fromDate.startOf("day").format("x"));
    }
    if(toDate && typeof toDate == 'object'){
        toDate = parseInt(toDate.endOf("day").format("x")) + 1;
    }
    let url = (exportKey == "distance") ? EXPORT_DISTANCE : EXPORT_GPS_QUALITY;
    url = `${BASE_GPS_MATRIX_STAG_URL}${url}/export?`;
    if (regionId) url = `${url}regionId=${regionId}&timeZone=${timeZone}`;
    if (fromDate) url = `${url}&fromDate=${EpochTimezone(fromDate,timeZone)}`;
    if (toDate) url = `${url}&toDate=${EpochTimezone(toDate,timeZone)}`;
    if(Object(JSON.parse(localStorage.getItem('authorities'))).Admin){
      url = `${url}&isAdmin=true`;
    }
    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: {Authorization: 'Bearer ' + token}
        });
    })
}

export function downLoadFrequencyDistanceData(regionId, fromDate,toDate) {
    const timeZone =getLocalStorageTz();
    if(fromDate && typeof fromDate == 'object'){
        fromDate = parseInt(fromDate.startOf("day").format("x"));
    }
    if(toDate && typeof toDate == 'object'){
        toDate = parseInt(toDate.endOf("day").format("x")) + 1;
    }
    let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REPORTS}/frequency/export?`;
    if (fromDate) url = `${url}fromDate=${EpochTimezone(fromDate,timeZone)}`;
    if (toDate) url = `${url}&toDate=${EpochTimezone(toDate,timeZone)}`;
    if (regionId) url = `${url}&regionId=${regionId}&timeZone=${timeZone}`;
    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: {Authorization: 'Bearer ' + token}
        });
    })
}

export function downLoadFrequencyDistanceDataYesterday(regionId, date, sortOrder, sortKey) {
    var timeZone=localStorage.getItem('timeZone')
    let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REPORTS}/frequency/exportByDate?`;
    if (date) url = `${url}date=${EpochTimezone(new Date(date).getTime(),timeZone)}`;
    if (sortKey && sortOrder) url = `${url}&sortKey=${sortKey}&order=${sortOrder}`;
    if (regionId) url = `${url}&regionId=${regionId}&timeZone=${timeZone}`;
    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: {Authorization: 'Bearer ' + token}
        });
    })
}

export function RestoreData(dataType, date, regionId,vehicleNo,selectedRegionTimezone) {
    let url = EXPORT_RESTORE_DATA;
    var timeZone=localStorage.getItem('timeZone')
    url = `${BASE_URL}${url}?`;
    if (date) url = `${url}date=${EpochTimezone(date,timeZone)}`;
    if (dataType) url = `${url}&dataType=${dataType}`;
    if (regionId && ['Raw','Vehicle'].includes(dataType)) url = `${url}&regionId=${regionId}`;
    if (vehicleNo && dataType==='Vehicle') url = `${url}&vehicleNo=${vehicleNo}`;
    if(selectedRegionTimezone){
        selectedRegionTimezone = selectedRegionTimezone.timeZone;
    }else{
        selectedRegionTimezone = timeZone;
    }
    url = `${url}&timeZone=${selectedRegionTimezone}`;
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function downloadFile(url) {
    var link = document.createElement('a');
    link.href = url;
    link.download = 'gps_data';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

}

export function fetchVehiclesList(regionId) {
    let url;
    url = `${BASE_URL}${GET_VEHICLES_LIST}?regionId=${regionId}`
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchBsdAvailablityReportData(fromDate,endDate,pageNo,selectedProtocol,selectedStatus,searchKey){
    let url;
    const timeZone =getLocalStorageTz();
    url = `${BASE_URL}bsd/device-availability?fromDate=${EpochTimezone(fromDate,timeZone)}&toDate=${EpochTimezone(endDate,timeZone)}&pageNo=${pageNo}&pageSize=10`
    if(selectedProtocol){
        url = `${url}&protocol=${selectedProtocol}`
    }
    if(selectedStatus){
        url = `${url}&availabilityStatus=${selectedStatus}`;
    }
    if(searchKey){
        url = `${url}&searchKey=${searchKey}`;
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchProtocol(){
    let url = `${BASE_URL}bsd/bsd-protocol/all`;

    return getAPIPromise(url,{
        method: 'get'
    })
}



export function exportBsdAvailablityReport(fromDate,endDate,pageNo,selectedProtocol,selectedStatus,searchKey){
    let url;
    const timeZone =getLocalStorageTz();
    if(typeof fromDate == 'object'){
        fromDate = parseInt(fromDate.startOf("day").format("x"));
    }
    if(typeof endDate == 'object'){
        endDate = parseInt(endDate.endOf("day").format("x")) + 1;
    }
    url = `${BASE_URL}bsd/device-availability/export?fromDate=${EpochTimezone(fromDate,timeZone)}&toDate=${EpochTimezone(endDate,timeZone)}&pageNo=${pageNo}&pageSize=10`
    if(selectedProtocol){
        url = `${url}&protocol=${selectedProtocol}`
    }
    if(selectedStatus){
        url = `${url}&availabilityStatus=${selectedStatus}`;
    }
    if(searchKey){
        url = `${url}&searchKey=${searchKey}`;
    }   
    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: {Authorization: 'Bearer ' + token}
        });
    })
}

export function fetchEtaAckMatrixReportData(fromDate,endDate,pageNo,city,searchKey,sortKey,sortOrder){
    let url;
    const timeZone =getLocalStorageTz();
    url = `${BASE_URL}bsd/eta-ack?fromTimeStamp=${EpochTimezone(fromDate,timeZone)}&toTimeStamp=${EpochTimezone(endDate,timeZone)}&pageNo=${pageNo}&pageSize=10`
    if(city){
        url = `${url}&city=${city}`
    }
   
    if(searchKey){
        url = `${url}&searchKey=${searchKey}`;
    }
    if (sortKey && sortOrder) url = `${url}&sortKey=${sortKey}&sortOrder=${sortOrder}`;

    return getAPIPromise(url, {
        method: 'get'
    });
}

export function exportEtaAckMatrixReport(fromDate,endDate,pageNo,city,searchKey,sortKey,sortOrder){
    let url;
    const timeZone =getLocalStorageTz();
    if(typeof fromDate == 'object'){
        fromDate = parseInt(fromDate.startOf("day").format("x"));
    }
    if(typeof endDate == 'object'){
        endDate = parseInt(endDate.endOf("day").format("x")) + 1;
    }
    url = `${BASE_URL}bsd/eta-ack/export?fromTimeStamp=${EpochTimezone(fromDate,timeZone)}&toTimeStamp=${EpochTimezone(endDate,timeZone)}&pageNo=${pageNo}&pageSize=10`
    if(city){
        url = `${url}&city=${city}`
    }
   
    if(searchKey){
        url = `${url}&searchKey=${searchKey}`;
    }
    if (sortKey && sortOrder) url = `${url}&sortKey=${sortKey}&sortOrder=${sortOrder}`;

    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: {Authorization: 'Bearer ' + token}
        });
    })
}

function getQueryParamForVehicleInAndOut(data) {
    const {  selectedCity, searchString, selectedAgency, selectedOperator, selectedDepot, regionId ,pageNo } = data;
    let { startDate, endDate } = data;
    let url = `?searchKey=${searchString || ''}`;
    
    if (selectedCity) {
        url += `&city=${selectedCity}`;
    }
    if (selectedAgency) {
        url += `&agency=${selectedAgency}`;
    }
    if (selectedOperator) {
        url += `&operator=${selectedOperator}`;
    }
    if (selectedDepot) {
        url += `&depot=${selectedDepot}`;
    }
    if (regionId) {
        url += `&regionId=${regionId}`;
    }
    
    if (typeof startDate === 'object') {
        startDate = parseInt(startDate.startOf('day').format('x'));
    }
    if (typeof endDate === 'object') {
        endDate = parseInt(endDate.endOf('day').format('x')) + 1;
    }
    url += `&fromDate=${EpochTimezone(startDate)}&toDate=${EpochTimezone(endDate)}&pageNo=${pageNo}&pageSize=10`;
    
    return url;
}

export function exportVehicleInOut(data){
    let url = `${BASE_GPS_MATRIX_STAG_URL}api/vehicle-in-out-event/export${getQueryParamForVehicleInAndOut(data)}`
    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, { methods: 'GET', headers: {Authorization: 'Bearer ' + token} });
    })
}

export function fetchVehicleInOutEventData(data){
    let url = `${BASE_GPS_MATRIX_STAG_URL}api/vehicle-in-out-event${getQueryParamForVehicleInAndOut(data)}`
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchDeviceConfig({selectedRegion,pageNo,searchString,startDate,endDate,commandStatus,commandResponseStatus}){
    let url = `${BASE_URL}api/device/config/v2?regionId=${selectedRegion}&pageNo=${pageNo}&pageSize=10`;
    if(searchString){
        url = `${url}&searchKey=${searchString}`;
    }
    if(startDate){
        url = `${url}&fromDate=${EpochTimezone(startDate)}&toDate=${EpochTimezone(endDate)}`
    }
    if(commandStatus){
        url = `${url}&commandStatus=${commandStatus}`
    }
    if(commandResponseStatus){
        url = `${url}&commandResponseStatus=${commandResponseStatus}`
    }

    return getAPIPromise(url, {
        method: 'get'
    });
}

export function createDeviceConfig (data,createMode=false){
    const url = `${BASE_URL}api/device/config/v2?regionId=${data.selectedRegion.value}&commandType=${data.commandType}`
    return getAPIPromise(url, {
        method:!createMode ? 'POST' : 'PUT',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        body: JSON.stringify(data.tableData),
    });
}

export function previewOfCommand (data,createMode=false){
    const url = `${BASE_URL}api/device/config/v2/commands?configCommand=$command_id,device_id,password,${data.command}`
    return getAPIPromise(url, {
        method:!createMode ? 'POST' : 'PUT',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        body: JSON.stringify([data.deviceId]),
    });
}
export function fetchDeviceResponseFromCommandId (commandId){
    const url = `${BASE_URL}api/device/config/v2/${commandId}/command-responses`
    return getAPIPromise(url, {
        method:'get'
    });
}

export function exportDeviceConfiguration(data){
    let url = `${BASE_URL}api/device/config/v2/export?regionId=${data.selectedRegion}&fromDate=${EpochTimezone(data.startDate)}&toDate=${EpochTimezone(data.endDate)}&pageNo=${data.pageNo}&pageSize=10`;
    if(data.searchString){
        url = `${url}&searchKey=${data.searchString}`;
    }
    if(data.commandStatus){
        url = `${url}&commandStatus=${data.commandStatus}`
    }
    if(data.commandResponseStatus){
        url = `${url}&commandResponseStatus=${data.commandResponseStatus}`
    }
    return getValidToken().then((token)=> {
        return FileDownLoader.download(url, { methods: 'GET', headers: {Authorization: 'Bearer ' + token,} });
    })
}
