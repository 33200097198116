const validateVehicle = ({
  selectedRegion,
  vehicleNo,
  city,
  agencyName,
  depotName,
  operator,
  seatingCapacity,
  vehicleType,
  gstOnTicket,
  collectionOnEtim,
  surchargeOnTicket,
  cameraIdError,
  serviceCategory,
  dispatchType,
  serviceStartTime,
  serviceEndTime,
  routeId
}) => {
  const mandatoryFields = [
    selectedRegion,
    vehicleNo,
    city,
    agencyName,
    depotName,
    operator,
    seatingCapacity,
    vehicleType,
    gstOnTicket,
    collectionOnEtim,
    surchargeOnTicket,
    serviceCategory,
    dispatchType,
  ];
  const isMandatoryFieldMissing = mandatoryFields.some((field) => !field);
  const dispatchTypeValueFixMandatory =
    dispatchType &&
    dispatchType.label === "Fix" &&
    (!serviceStartTime || !serviceEndTime || !routeId);
  const dispatchTypeValueAutoOrEtimMandatory =
    dispatchType &&
    ["Etim", "Auto"].includes(dispatchType.label) &&
    (!serviceStartTime || !serviceEndTime);
  const form = {};
  if (
    isMandatoryFieldMissing ||
    cameraIdError ||
    dispatchTypeValueFixMandatory ||
    dispatchTypeValueAutoOrEtimMandatory
  ) {
    form._error = "Required field missing";
    form.invalid = true;
  } else {
    form.invalid = false;
  }
  return form;
};
export default validateVehicle;
