import React from "react";
import { Button } from "react-bootstrap";
import { MessageType } from "../../../../../Constant";
import validateVehicle from "../../validator/vehicleValidators";
import { createOrUpdateVehicle,generateTimestamp } from "../../../../../services/vehicleService";
import { VehicleState } from "../../models/vehicleModels";
import CityToAgencyMapping from "./cityToAgencyMapping";
import Config from "../../../../../config";
import Select from "react-select";
import { DatePicker,SingleDateTimePicker } from "../../../../../shared/widgets";
import * as moment from "moment";
import { fetchProtocolType, fetchVehicleMetaData,fetchBusClassification,fetchTags,fetchServiceProviderWithCity,fetchFuelTypes } from "../../../../../services/vehicleMasterService";
import { fetchRegions,fetchRoutesData,fetchDispatchTypesData } from "../../../../../services/regionService";
import { EpochTimezone } from "../../../../../shared/utils";
import { mappedRoutesData,generateRouteIdOptions, generateLabelForRoute } from './util';
export default class VehicleCreate extends React.Component {
    constructor(props) {
        super(props);
        let m = moment(new Date());
        this.state = {
            vehicleCode : this.props.updateSelection && this.props.updateSelection.vehicleCode ? this.props.updateSelection.vehicleCode : '',
            stayOpen: false,
            vehicleNo: this.props.updateSelection && this.props.updateSelection.vehicleNo ? this.props.updateSelection.vehicleNo : '',
            deviceId : this.props.updateSelection && this.props.updateSelection.deviceId ? this.props.updateSelection.deviceId : '',
            simCard : this.props.updateSelection && this.props.updateSelection.simCard ? this.props.updateSelection.simCard : '',
            afcsAccountId : this.props.updateSelection && this.props.updateSelection.afcsAccountId ? this.props.updateSelection.afcsAccountId : '',
            make : this.props.updateSelection && this.props.updateSelection.make ? this.props.updateSelection.make : '',
            owner : this.props.updateSelection && this.props.updateSelection.owner ? this.props.updateSelection.owner : '',
            model : this.props.updateSelection && this.props.updateSelection.model ? this.props.updateSelection.model : '',
            version : this.props.updateSelection && this.props.updateSelection.version ? this.props.updateSelection.version : '',
            secondarySim : this.props.updateSelection && this.props.updateSelection.secondarySim ? this.props.updateSelection.secondarySim : '',
            chasisNumber : this.props.updateSelection && this.props.updateSelection.chasisNumber ? this.props.updateSelection.chasisNumber : '',
            engineNumber : this.props.updateSelection && this.props.updateSelection.engineNumber ? this.props.updateSelection.engineNumber : '',
            removeSelected: true,
            rtl: false,
            disabled: false,
            form: {},
            regions: [],
            serviceTypeMeta: [],
            operationTypeMeta: [],
            vehicleTypeMeta: [],
            vehicleType: this.props.updateSelection ? this.props.updateSelection.vehicleType ? { label: this.props.updateSelection.vehicleType , value: this.props.updateSelection.vehicleType  } : '' : '',
            serviceType: this.props.updateSelection ? this.props.updateSelection.serviceType ? { label: this.props.updateSelection.serviceType , value: this.props.updateSelection.serviceType  } : '' : '',
            operationType: this.props.updateSelection ? this.props.updateSelection.operationType ? { label: this.props.updateSelection.operationType , value: this.props.updateSelection.operationType  } : '' : '',
            gstOnTicket: this.props.updateSelection ? this.props.updateSelection.gstOnTicket ? { label: this.props.updateSelection.gstOnTicket , value: this.props.updateSelection.gstOnTicket  } : '' : '',
            surchargeOnTicket: this.props.updateSelection ? this.props.updateSelection.surchargeOnTicket ? { label: this.props.updateSelection.surchargeOnTicket , value: this.props.updateSelection.surchargeOnTicket  } : '' : '',
            collectionOnEtim: this.props.updateSelection ? this.props.updateSelection.collectionOnEtim ? { label: this.props.updateSelection.collectionOnEtim , value: this.props.updateSelection.collectionOnEtim  } : '' : '',
            fuelPlan: this.props.updateSelection ? this.props.updateSelection.fuelPlan ? { label: 'Yes', value: 'Yes' } :{ label: 'No', value: 'No' }: { label: 'No', value: 'No' },
            gpsRequiredFromEtim: this.props.updateSelection ? this.props.updateSelection.gpsRequiredFromEtim ? { label: 'Yes', value: 'Yes' } :{ label: 'No', value: 'No' }: { label: 'No', value: 'No' },
            fwdToShuttl: this.props.updateSelection ? this.props.updateSelection.fwdToShuttl ? { label: 'Yes', value: 'Yes' } :{ label: 'No', value: 'No' }: { label: 'No', value: 'No' },
            etimFrequency : this.props.updateSelection && this.props.updateSelection.etimFrequency ? this.props.updateSelection.etimFrequency : 30,
            protocolType: this.props.updateSelection ? this.props.updateSelection.protocolType ? { label: this.props.updateSelection.protocolType , value: this.props.updateSelection.protocolType  } : '' : '',
            applicationMeta: [],
            applications: [],
            cameras : this.props.updateSelection && this.props.updateSelection.cameras ?  this.FilterCameras(this.props.updateSelection.cameras) : '',            city: '',
            depotName: '',
            agencyName: '',
            operator: '',
            selectedDate: this.props.updateSelection && this.props.updateSelection.registrationDate ? this.props.updateSelection.registrationDate : '',
            registrationDate: this.props.updateSelection && this.props.updateSelection.registrationDate ? moment(new Date(this.props.updateSelection.registrationDate)) : m,
            propertyApplicableOptions: [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }],
            protocolTypeList: [],
            seatingCapacity:this.props.updateSelection && this.props.updateSelection.seatingCapacity ? this.props.updateSelection.seatingCapacity : '',
            standingCapacity:this.props.updateSelection && this.props.updateSelection.standingCapacity ? this.props.updateSelection.standingCapacity : '',
            selectedRegion:{ label: this.capitalize(props.selectedRegion), value: props.selectedRegion },
            cameraIdError : false,
            busClassificationOptions:[],
            serviceCategory:this.props.updateSelection && this.props.updateSelection.serviceCategory ? this.props.updateSelection.serviceCategory : '',
            serviceProvider:this.props.updateSelection && this.props.updateSelection.serviceProvider ? this.props.updateSelection.serviceProvider : '',
            serviceProviderOptions:[],
            tagOptions:[],
            tag:this.props.updateSelection && this.props.updateSelection.tag ? this.props.updateSelection.tag : '',
            fuelType:this.props.updateSelection && this.props.updateSelection.fuelType ? this.props.updateSelection.fuelType : '',
            dispatchTypeOptions:[],
            dispatchType: this.props.updateSelection && this.props.updateSelection.dispatchType ? this.props.updateSelection.dispatchType : '',
            routeIdOptions:[],
            serviceStartTime:'',
            serviceEndTime:''
        };
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onCityMappingChange = this.onCityMappingChange.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.fetchBusClassification = this.fetchBusClassification.bind(this);
        this.fetchServiceProviderOptions = this.fetchServiceProviderOptions.bind(this);
        this.fetchTags = this.fetchTags.bind(this);
        this.fetchRoutes = this.fetchRoutes.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchRegions();
        this.fetchProtocolTypeList();
        this.fetchVehicleData('vehicleType', 'vehicleTypeMeta')
        this.fetchVehicleData('serviceType', 'serviceTypeMeta')
        this.fetchVehicleData('operationType', 'operationTypeMeta')
        this.fetchVehicleData('applications', 'applicationMeta');
        this.fetchTags(null);
        this.getFuelOptions();
        this.fetchDispatchTypes();
        if(this.props.updateSelection){
        this.fetchRoutes();
        }
        this.fillVehicleDispatchConfigDto();
    }

    fetchBusClassification(cityData){
        this.setState(
          { busClassificationOptions: [], serviceCategory: "" },
          () => {
            this.updateFormErrorState();
          }
        );
        fetchBusClassification(cityData).then((res) => {
            if(res && res.length){
                const data = res.reduce((acc,curr)=>{
                    acc.push({label:curr.serviceName,value:curr.serviceName,id:curr.serviceId})
                    return acc;
                },[]);
                this.setState({busClassificationOptions:data});
                if(this.props.updateSelection && this.props.updateSelection.serviceCategory){
                    const serverServiceCategory = data.find((i)=>i.value == this.props.updateSelection.serviceCategory);
                    if(serverServiceCategory){
                        this.setState(
                          { serviceCategory: serverServiceCategory },
                          () => {
                            this.updateFormErrorState();
                          }
                        );
                    }
                }
            }
        }).catch(function (err) {
            console.log(err);
        });
    }

    fetchServiceProviderOptions(cityData){
        this.setState({
            serviceProviderOptions:[],serviceProvider:""
        },()=>{
            this.updateFormErrorState();
        });
        fetchServiceProviderWithCity(cityData)
        .then((res) => {
            if(res && res.length){
                const data = res.reduce((acc,curr)=>{
                    acc.push({label:curr.serviceProviderName,value:curr.serviceProviderName,id:curr.serviceProviderId})
                    return acc;
                },[]);
                this.setState({serviceProviderOptions:data});
                if(this.props.updateSelection && this.props.updateSelection.serviceProvider){
                    const serverServiceProviderName= data.find((i)=>i.value == this.props.updateSelection.serviceProvider);
                    if(serverServiceProviderName){
                        this.setState(
                          { serviceProvider: serverServiceProviderName },
                          () => {
                            this.updateFormErrorState();
                          }
                        );
                    }
                }
            }
        }).catch(function (err) {
            console.log(err);
        });
    }

    componentWillReceiveProps(nextProps) {
        console.log(" in componentWillReceiveProps")
        if (nextProps.updateSelection && nextProps.updateSelection.registrationDate && nextProps.updateSelection.registrationDate != this.state.registrationDate) {
            this.setState({
                registrationDate: moment(new Date(nextProps.updateSelection.registrationDate)),
                selectedDate: nextProps.updateSelection.registrationDate
            })
            console.log(" in componentWillReceiveProps if")
            this.updateFormData(nextProps.updateSelection);
        } else {
            this.updateFormData(nextProps.updateSelection);
            this.updateFormErrorState()
            console.log(" in componentWillReceiveProps else")
        }
    }

    updateFormData(vehicle) {
        if(vehicle){
            if(this.state.vehicleNo && !vehicle.vehicleNo){
                vehicle.vehicleNo = this.state.vehicleNo;
            }
            this.setState(new VehicleState(vehicle), () => {
                this.updateFormErrorState();

            });
        }

    }

    FilterCameras(val){
        var cameraIds = val.map(function(item) {
            return item['cameraId'];
        });
        return cameraIds.toString();
    }

    onCityMappingChange(data) {
        const checkIsCityOrAgencyChange = ()=>{
            if(!data.agencyName) return false;
            if((this.state.agencyName || JSON.stringify(this.state.agencyName)!=JSON.stringify(data.agencyName)) || 
            (data.city && (this.state.city || JSON.stringify(this.state.city)!=JSON.stringify(data.city)))
            ) return true;
            return false;
        }
        // On City Change fetch service Category Options
        if(checkIsCityOrAgencyChange()){
            const payload = {
                agencyName:data.agencyName.value.name,
                cityId: data.city.value.id
            }
            this.fetchBusClassification(payload);
            this.fetchServiceProviderOptions(payload);
        }
        if((this.state.city && JSON.stringify(this.state.city)!=JSON.stringify(data.city)) ||
        (this.state.agencyName && JSON.stringify(this.state.agencyName)!=JSON.stringify(data.agencyName))){
            this.setState({routeId:null,routeIdOptions:[]},()=>{this.updateFormErrorState();});
        }
        this.setState(data, () => { 
            this.updateFormErrorState(); 
            if(this.state.city && this.state.agencyName && this.state.routeIdOptions.length == 0){
                this.fetchRoutes();
            }
        });
    }

    preventSpecialCharacter(event, value) {
        const re = /^[a-zA-Z0-9]+$/
        if (!re.test(value)) {
            event.preventDefault();
        }
    }

    preventAlphaCharacter(event, value) {
        const re = /^[0-9]+$/
        if (!re.test(value)) {
            event.preventDefault();
        }
    }


    dateChange(date) {
        this.setState({
            selectedDate: date.startOf('day').format('x'),
            registrationDate: date
        });
    }


    fetchVehicleData(masterType, options) {
        fetchVehicleMetaData(masterType).then((res => {
            if (this.mounted) {
                this.setState({
                    [options]: res.map((data) => {
                        if (data.id) {
                            return { label: data.name, value: data }
                        } else {
                            return { label: data, value: data }
                        }
                    })
                });
                if (this.props.updateSelection && masterType === 'applications') {
                    let applications = this.props.updateSelection.applications;


                    if (applications.length > 0) {
                        this.setState({
                            applications: this.state.applicationMeta ? this.state.applicationMeta.filter(app => {
                                return applications.some(b => app.label === b.name)
                            }) : []
                        }, () => {
                            this.updateFormErrorState()
                        })
                    }
                }
            }

        })).catch(err => {
            this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
        });
    }

    
    fetchRegions() {
        fetchRegions().then((res) => {
            if (res && res.items) {
                const orderedNewOptions = res.items.sort((a,b)=>{
                    return a.regionId > b.regionId ? 1 : b.regionId > a.regionId ? -1 : 0;
                });

                this.setState({
                    regions: orderedNewOptions.map((data) => {
                        return { label: this.capitalize(data.regionId), value: data.regionId }
                    })
                }, () => this.updateFormErrorState())
            }

        }).catch(function (err) {
            console.log(err);
        });
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      }
    
    fetchTags(region){
        this.setState({tagOptions:[]},
        () => this.updateFormErrorState()
        );
        fetchTags(region ? region : this.state.selectedRegion)
          .then((res) => {
            if (res) {
              this.setState(
                {
                  tagOptions: res.map((el) => {
                    return { ...el, label: el.tag, value: el.tag };
                  }),
                },
                () => this.updateFormErrorState()
              );
            }
          })
          .catch((err)=>{
            console.log(err);
             this.setState({tagOptions:[],tag:''})
          }); 
    }
    getCamerasDefaultValue(){
        if(this.props.updateSelection && this.props.updateSelection.cameras){
            return this.FilterCameras(this.props.updateSelection.cameras); 
        }
        return "";
    }
    
    getCamerasDefaultValue2(){
        if(!this.props.updateSelection || !this.props.updateSelection.cameras) return "";
        if(this.props.updateSelection.cameras.length){
            return this.FilterCameras(this.props.updateSelection.cameras); 
        }
        return "";
    }

    getFuelOptions = ()=>{
        fetchFuelTypes().then((res)=>{
         this.setState({
             fuelOptions: res.map((data) => {
                 return { label: data, value: data }
             })
         })
        })
    }
    
    render() {
        return (
            <div className="row">
                <form className="form-horizontal">
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label"><span>Region Id</span></label>
                                <div className="col-sm-9">
                                    {/* <span>{this.props.selectedRegion}</span> */}
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            onChange={(value) => {
                                                this.handleSelectChange('selectedRegion', value)

                                            }}
                                            options={this.state.regions}
                                            placeholder="Select Region"
                                            clearable={false}
                                            rtl={this.state.rtl}
                                            value={this.state.selectedRegion}
                                        />
                                        :
                                        this.props.updateSelection.selectedRegion}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label"><span>Vehicle No</span></label>
                                <div className="col-sm-9 ">
                                    {(this.props.showInfo || this.props.updateSelection) ?
                                        <span>{this.props.updateSelection.vehicleNo}</span> :
                                        <span>
                                            <input type="text" autoComplete="off" className="form-control" id="vehicleNo"
                                                placeholder="Enter Vehicle No"
                                                onChange={(value) => {
                                                    this.setState({ vehicleNo: value.target.value.trim() }, () => {
                                                        this.updateFormErrorState()
                                                    })
                                                }}
                                                onKeyPress={(value) => {
                                                    this.preventSpecialCharacter(value, value.key);
                                                }}
                                                onPaste={(value) => {
                                                    this.preventSpecialCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                                }}
                                                defaultValue={this.props.updateSelection ? this.props.updateSelection.vehicleNo : ""}
                                            />
                                            <span className="info-text">Only Alphabets and Numbers are allowed</span>
                                        </span>}

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Vehicle Code</label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <input type="text" className="form-control" id="vehicleCode"
                                            placeholder="Enter Vehicle code"
                                            onChange={(value) => {
                                                this.setState({ vehicleCode: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.vehicleCode : ""} />
                                        :
                                        <span>{this.props.updateSelection.vehicleCode}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Device Id</label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <input type="text" className="form-control" id="deviceId"
                                            placeholder="Enter Device Id"
                                            onChange={(value) => {
                                                this.setState({ deviceId: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.deviceId : ""} />
                                        :
                                        <span>{this.props.updateSelection.deviceId}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {Config.operatorBasedVehicle &&
                        <CityToAgencyMapping showInfo={this.props.showInfo} updateSelection={this.props.updateSelection}
                            onDataChange={this.onCityMappingChange} />}



                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Make</label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.make}</span> :
                                        <input type="text" className="form-control" id="make"
                                            placeholder="Enter Value..."
                                            onChange={(value) => {
                                                this.setState({ make: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.make : ""} />

                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Owner</label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.owner}</span> :
                                        <input type="text" className="form-control" id="owner"
                                            placeholder="Enter Owner"
                                            onChange={(value) => {
                                                this.setState({ owner: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.owner : ""} />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12" >
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Model</label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.model}</span> :
                                        <input type="text" className="form-control" id="model"
                                            placeholder="Enter Model"
                                            onChange={(value) => {
                                                this.setState({ model: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.model : ""} />

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Version</label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.version}</span> :
                                        <input type="text" className="form-control" id="version"
                                            placeholder="Enter version"
                                            onChange={(value) => {
                                                this.setState({ version: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.version : ""} />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label"><span>Vehicle Type</span></label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('vehicleType', value)
                                            }}
                                            options={this.state.vehicleTypeMeta}
                                            placeholder="Select Vehicle Type"
                                            removeSelected={this.state.removeSelected}
                                            rtl={this.state.rtl}
                                            value={this.state.vehicleType}
                                        />
                                        :
                                        this.props.updateSelection.vehicleType}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Registration Date</label>
                                <div className="col-sm-9 ">
                                    {!this.props.showInfo ?
                                        <div className="custom-date-input">
                                            <DatePicker date={this.state.registrationDate} onDateChange={this.dateChange} withoutDefaultBorder={true} />
                                        </div>
                                        :
                                        this.props.updateSelection.registrationDate}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Service Type</label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('serviceType', value)
                                            }}
                                            options={this.state.serviceTypeMeta}
                                            placeholder="Select Service Type"
                                            removeSelected={this.state.removeSelected}
                                            rtl={this.state.rtl}
                                            value={this.state.serviceType}
                                        />
                                        :
                                        this.props.updateSelection.serviceType}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Operation Type</label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('operationType', value)
                                            }}
                                            options={this.state.operationTypeMeta}
                                            placeholder="Select Operation Type"
                                            removeSelected={this.state.removeSelected}
                                            rtl={this.state.rtl}
                                            value={this.state.operationType}
                                        />
                                        :
                                        this.props.updateSelection.operationType}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label"><span>Seating Capacity</span></label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.seatingCapacity}</span> :
                                        <input type="number" min="0" className="form-control" id="seatingCapacity"
                                            placeholder="Enter Seating Capacity"
                                            onChange={(value) => {
                                                this.setState({ seatingCapacity: value.target.value.trim() }, () => {
                                                    this.updateFormErrorState()
                                                })
                                            }}
                                            onKeyPress={(value) => {
                                                this.preventAlphaCharacter(value, value.key);
                                            }}
                                            onPaste={(value) => {
                                                this.preventAlphaCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.seatingCapacity : ""}
                                        />}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Standing Capacity</label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.standingCapacity}</span> :
                                        <input type="number" min="0" className="form-control" id="standingCapacity"
                                            placeholder="Enter Standing Capacity"
                                            onChange={(value) => {
                                                this.setState({ standingCapacity: value.target.value.trim() })
                                            }}
                                            onKeyPress={(value) => {
                                                this.preventAlphaCharacter(value, value.key);
                                            }}
                                            onPaste={(value) => {
                                                this.preventAlphaCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.standingCapacity : ""}
                                        />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">SIM 1</label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.simCard}</span> :
                                        <input type="text" className="form-control" id="simNo"
                                            placeholder="Enter SIM number"
                                            onChange={(value) => {
                                                this.setState({ simCard: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.simCard : ""} />

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">SIM 2</label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.secondarySim}</span> :
                                        <input type="text" className="form-control" id="simNo2"
                                            placeholder="Enter SIM number"
                                            onChange={(value) => {
                                                this.setState({ secondarySim: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.secondarySim : ""} />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Application</label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            multi
                                            onChange={(value) => {
                                                this.handleSelectChange('applications', value)
                                            }}
                                            options={this.state.applicationMeta}
                                            placeholder="Select Applications"
                                            removeSelected={this.state.removeSelected}
                                            rtl={this.state.rtl}
                                            value={this.state.applications}
                                        />
                                        :
                                        this.props.updateSelection.applications}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label"><span>GST on Ticket</span></label>
                                <div className="col-sm-9">


                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('gstOnTicket', value)
                                            }}
                                            options={this.state.propertyApplicableOptions}
                                            placeholder="Select"
                                            clearable={false}
                                            rtl={this.state.rtl}
                                            value={this.state.gstOnTicket}
                                        />
                                        :
                                        this.props.updateSelection.gstOnTicket}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label"><span>Surcharge On Ticket </span></label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('surchargeOnTicket', value)
                                            }}
                                            clearable={false}
                                            options={this.state.propertyApplicableOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.surchargeOnTicket}
                                        />
                                        :
                                        this.props.updateSelection.surchargeOnTicket}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label"><span>Collection On Etim</span></label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('collectionOnEtim', value)
                                            }}
                                            options={this.state.propertyApplicableOptions}
                                            placeholder="Select"
                                            clearable={false}
                                            rtl={this.state.rtl}
                                            value={this.state.collectionOnEtim}
                                        />
                                        :
                                        this.props.updateSelection.collectionOnEtim}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Afcs Account Id</label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <input type="text" className="form-control" id="afcsAccountId"
                                            placeholder="Enter Acount Id"
                                            onChange={(value) => {
                                                this.setState({ afcsAccountId: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.afcsAccountId : ""} />
                                        :
                                        <span>{this.props.updateSelection.afcsAccountId}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Fuel Plan </label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('fuelPlan', value)
                                            }}
                                            clearable={false}
                                            options={this.state.propertyApplicableOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.fuelPlan}
                                        />
                                        :
                                        this.props.updateSelection.fuelPlan}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                    <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">GPS From Etim </label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('gpsRequiredFromEtim', value)
                                            }}
                                            clearable={false}
                                            options={this.state.propertyApplicableOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.gpsRequiredFromEtim}
                                        />
                                        :
                                        this.props.updateSelection.gpsRequiredFromEtim}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Etim Frequency</label>
                                <div className="col-sm-6">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.etimFrequency}</span> :
                                        <input type="number" min="1" className="form-control" id="etimFrequency"
                                            placeholder="Enter Frequency"
                                            onChange={(value) => {
                                                this.setState({ etimFrequency: value.target.value.trim() })
                                            }}
                                            onKeyPress={(value) => {
                                                this.preventAlphaCharacter(value, value.key);
                                            }}
                                            onPaste={(value) => {
                                                this.preventAlphaCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.etimFrequency : 30}
                                        />}
                                </div>(seconds)
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Forward To Shuttl </label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('fwdToShuttl', value)
                                            }}
                                            clearable={false}
                                            options={this.state.propertyApplicableOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.fwdToShuttl}
                                        />
                                        :
                                        this.props.updateSelection.fwdToShuttl}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="protocolType"
                                    className="control-label col-sm-3 form-label">Protocol Type </label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.handleSelectChange('protocolType', value)
                                            }}
                                            clearable={false}
                                            options={this.state.protocolTypeList}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.protocolType}
                                        />
                                        :
                                        this.props.updateSelection.protocolType}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Engine Number</label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.engineNumber}</span> :
                                        <span>
                                            <input type="text" className="form-control" id="engineNumber" autoComplete="off"
                                            placeholder="Enter Engine number"
                                            onChange={(value) => {
                                                this.setState({ engineNumber: value.target.value.trim() })
                                            }}
                                            onKeyPress={(value) => {
                                                this.preventSpecialCharacter(value, value.key);
                                            }}
                                            onPaste={(value) => {
                                                this.preventSpecialCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                            }}
                                            disabled={!(this.state.protocolType && this.state.protocolType.label==='AIS')}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.engineNumber : ""} />
                                            <span className="info-text">Only Alphabets and Numbers are allowed</span>
                                        </span>

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="name7"
                                    className="control-label col-sm-3 form-label">Chasis Number</label>
                                <div className="col-sm-9">
                                    {this.props.showInfo ?
                                        <span>{this.props.updateSelection.chasisNumber}</span> :
                                        <span>
                                            <input type="text" className="form-control" id="chasisNumber" autoComplete="off"
                                            placeholder="Enter Chasis number"
                                            onChange={(value) => {
                                                this.setState({ chasisNumber: value.target.value.trim() })
                                            }}
                                            onKeyPress={(value) => {
                                                this.preventSpecialCharacter(value, value.key);
                                            }}
                                            onPaste={(value) => {
                                                this.preventSpecialCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                            }}
                                            disabled={!(this.state.protocolType && this.state.protocolType.label==='AIS')}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.chasisNumber : ""} />
                                            <span className="info-text">Only Alphabets and Numbers are allowed</span>
                                        </span>

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="protocolType"
                                    className="control-label col-sm-3 form-label"><span>Service Cateogory</span> </label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                
                                                this.setState({serviceCategory:value},()=>{
                                                    this.updateFormErrorState();
                                                })
                                            }}
                                            clearable={false}
                                            options={this.state.busClassificationOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.serviceCategory}
                                        />
                                         :
                                        this.props.updateSelection.serviceCategory} 
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="protocolType"
                                    className="control-label col-sm-3 form-label">Tag</label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                
                                                this.setState({tag:value},()=>{
                                                    this.updateFormErrorState();
                                                })
                                            }}
                                            clearable={false}
                                            options={this.state.tagOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.tag.tag}
                                        />
                                         :
                                        this.props.updateSelection.tag} 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="protocolType"
                                    className="control-label col-sm-3 form-label">Service Provider</label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.setState({serviceProvider:value},()=>{
                                                    this.updateFormErrorState();
                                                })
                                            }}
                                            clearable={false}
                                            options={this.state.serviceProviderOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.serviceProvider}
                                        />
                                         :
                                        this.props.updateSelection.serviceProvider} 
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="protocolType"
                                    className="control-label col-sm-3 form-label">Fuel Type</label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            onChange={(value) => {
                                                this.setState({fuelType:value},()=>{
                                                    this.updateFormErrorState();
                                                })
                                            }}
                                            clearable={false}
                                            options={this.state.fuelOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.fuelType}
                                        />
                                         :
                                        this.props.updateSelection.fuelType} 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="protocolType"
                                    className="control-label col-sm-3 form-label"><span>
                                      Dispatch Type  </span></label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            disabled={this.state.disabled}
                                            onChange={(value) => {
                                                this.setState({dispatchType:value},()=>{
                                                    this.updateFormErrorState();
                                                })
                                            }}
                                            clearable={false}
                                            options={this.state.dispatchTypeOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.dispatchType}
                                        />
                                         :
                                        this.props.updateSelection.serviceProvider} 
                                </div>
                            </div>
                        </div>
                        {this.state.dispatchType && this.state.dispatchType.label === 'Fix' && <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="protocolType"
                                    className="control-label col-sm-3 form-label"><span>Route</span></label>
                                <div className="col-sm-9">
                                    {!this.props.showInfo ?
                                        <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            onChange={(value) => {
                                                this.setState({routeId:value},()=>{
                                                    this.updateFormErrorState();
                                                })
                                            }}
                                            clearable={false}
                                            options={this.state.routeIdOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.routeId}
                                        />
                                         :
                                        this.props.updateSelection.fuelType} 
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                   {this.state.dispatchType && this.state.dispatchType.label !=='Manual' && <div className="col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="protocolType"
                                    className="control-label col-sm-3 form-label"><span>Service Start Time</span></label>
                                <div className="col-sm-9">
                                        <SingleDateTimePicker
                                            id="serviceStartTime"
                                            date={this.state.serviceStartTime}
                                            onDateChange={this.fromDateChange}
                                            createVehicle ={true}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="protocolType"
                                    className="control-label col-sm-3 form-label"><span>Service End Time</span></label>
                                <div className="col-sm-9">
                                        <SingleDateTimePicker
                                            id="serviceEndTime"
                                            date={this.state.serviceEndTime}
                                            onDateChange={this.toDateChange}
                                            createVehicle ={true}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="col-sm-12 form-view">
                        <Button onClick={() => this.props.onClose()}>Close</Button>
                        {!this.props.showInfo && <Button bsStyle="primary"
                            onClick={() => {
                                this.setState({ loading: true })
                                this.fetchAddUpdate()
                            }}
                            disabled={this.state.form.invalid || this.state.loading}>{this.state.loading ? 'Saving...' : 'Save changes'}</Button>}
                    </div>

                </form>
            </div>
        );

    }

    /**
     * Used to Add/Update Vehicle
     */
    fetchAddUpdate() {
        createOrUpdateVehicle(this.state.selectedRegion.value, this.props.updateSelection, this.state, this.props.email).then((response) => {
            if (this.mounted) {
                this.setState({ loading: false });
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
            }
        }).catch((err) => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.onComplete(MessageType.ERROR, ("Invalid token"));
            } else if (this.mounted) {
                this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
            }
        });

    }


    /**
     * validates vehicle from data
     * @param target
     */
    updateFormErrorState() {
        console.log("this is state",this.state)
        let formState = validateVehicle(this.state);
        let {routeId,serviceStartTime,serviceEndTime} = this.state;
        if(this.state.dispatchType && ['Auto','Etim'].includes(this.state.dispatchType.label)){
           routeId = null;
        }
        if(this.state.dispatchType && this.state.dispatchType.label === 'Manual'){
            routeId = null;
            serviceStartTime = null;
            serviceEndTime = null;
        }
        this.setState({
            form: formState,
            routeId,
            serviceStartTime,    
            serviceEndTime
        }, () => {
            console.log("form", this.state.form)
        });
    }

    /**
     *handles the vehicles type change
     * @param value
     */
    handleSelectChange(key, value) {
        if(key == 'selectedRegion'){
            this.fetchTags(value);
        }
        this.setState({ [key]: value }, () => {
            this.updateFormErrorState();
        });

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchProtocolTypeList() {
        fetchProtocolType().then((res => {
            if (this.mounted) {
                this.setState({
                    protocolTypeList: res.content.map(data => ({
                        label: data.name, value: data.name
                    }))
                });
            }

        })).catch(err => {
            if ((err.status === 401 || err.message==='Invalid token')) {
                this.props.setTokenExpired(true);
            } else if (this.mounted) {
                this.setState({ citiesOptions: [] });
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description ? err.description : "Error while fetching master data"
                });
            }
        }
        );
    }

    fromDateChange = (date) => {
        this.setState(
          {
            serviceStartTime: this.convertToEpoch(date),
          },
          () => {
            this.updateFormErrorState();
          }
        );
      }
    
      toDateChange = (date) => {
        this.setState({ serviceEndTime: this.convertToEpoch(date) }, () => {
          this.updateFormErrorState();
        });
    }
    convertToEpoch(date) {
        const epochTime = new Date(date).getTime();
        return EpochTimezone(epochTime, "Asia/Kolkata");
    }

    fetchRoutes ()  {
        let city = this.state.city.label || '';
        let agencyName = this.state.agencyName.label || '';
        if(!city){
            city = this.props.updateSelection.cityMaster.name;
        }
        if(!agencyName){
            agencyName = this.props.updateSelection.agency.name;
        }
        fetchRoutesData(city,agencyName).then(res => {
            res = mappedRoutesData(res);
            let routeId = null;
            if(this.props.updateSelection && this.props.updateSelection.vehicleDispatchConfigDto){
                const routeIdObj = res.find((r)=>r.route_id == this.props.updateSelection.vehicleDispatchConfigDto.routeId);
                if(routeIdObj){
                    routeId = {
                        label:generateLabelForRoute(routeIdObj),
                        value:routeIdObj.route_id
                    }
                }
            }
            let state = {
                routeIdOptions: generateRouteIdOptions(res)
            };
            if(routeId) state['routeId'] = routeId;
            this.setState(state);
        }).catch(err => this.handleErrorCallback(err,'routeIdOptions'))
    }

    handleErrorCallback = (err,key)=>{
        if ((err.status === 401 || err.message === 'Invalid token')) {
            this.props.setTokenExpired(true);
        } else {
            if(this.state[key]){
                this.setState({ [key]: [] });
            }
            this.props.initiateNotification({type:MessageType.ERROR, message: err.description ? err.description : "Error while fetching routes data"});
        }
    }

    fetchDispatchTypes(){
        fetchDispatchTypesData().then(res => {
                this.setState({
                    dispatchTypeOptions: res.map(data => ({
                        label: data, value: data
                    }))
                });
        }).catch(err => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.setTokenExpired(true);
            } else if (this.mounted) {
                this.setState({ dispatchTypeOptions: [] });
                this.props.initiateNotification({type:MessageType.ERROR, message: err.description ? err.description : "Error while fetching dispatch options"});
            }
        }
        );
    }

    fillVehicleDispatchConfigDto = () => {
        if(this.props.updateSelection && this.props.updateSelection.vehicleDispatchConfigDto){
            const data = this.props.updateSelection.vehicleDispatchConfigDto;
            let serviceEndTime = new Date().getTime(),
                serviceStartTime = new Date().getTime();
            try{
                serviceStartTime = generateTimestamp(data.serviceStartTime);
                serviceEndTime = generateTimestamp(data.serviceEndTime);
            }catch(e){
                console.log(e);
            }
            this.setState({
                dispatchType: {label: data.dispatchType, value: data.dispatchType},
                serviceStartTime,
                serviceEndTime
            })
        }
    }
}