import React,{useEffect, useState} from "react";
import {VehicleInfoItem} from "./vehicleInfoItem";
import { fetchBusClassification,fetchServiceProviderWithCity } from "../../../../../services/vehicleMasterService";
import { fetchRoutesData } from "../../../../../services/regionService";
import { generateLabelForRoute } from '../create/util'; 
export const VehicleDetailView = (props)=>{
    const [data,setData] = useState(props.data);
    const [routeNameCorrespondeToRouteId,setRouteNameCorrespondeToRouteId] = useState('');
    useEffect(()=>{
        const fetchData = async () => {
            const { cityMaster, serviceCategory, serviceProvider, agency} = props.data;
            const agencyName = agency.name;
    
            const [busClassificationResult, serviceProviderResult, routesResult] = await Promise.allSettled([
                fetchBusClassification({ agencyName,cityId: cityMaster.id } ),
                fetchServiceProviderWithCity({ agencyName,cityId: cityMaster.id }),
                fetchRoutesData(cityMaster.name,agencyName)
            ]);
    
            let updateData = { ...data };

            function extractData(info,colCompare,fieldCompare) {
                if (info.status !== 'fulfilled' || !info.value || !info.value.length) {
                    return null;
                }
                return info.value.find(i => i[colCompare] == fieldCompare);
            }

            const serverServiceCategory = extractData(busClassificationResult,'serviceId', serviceCategory);
            if (serverServiceCategory) {
                updateData.serviceCategory = serverServiceCategory.serviceName;
            }

            const serverServiceProvider = extractData(serviceProviderResult,'serviceProviderId', serviceProvider);
            if (serverServiceProvider) {
                updateData.serviceProvider = serverServiceProvider.serviceProviderName;
            }
            if(data.vehicleDispatchConfigDto && data.vehicleDispatchConfigDto.routeId){
                const routeIdObj = routesResult.value.find((r)=>r.route_id == data.vehicleDispatchConfigDto.routeId);
                if(routeIdObj){
                    setRouteNameCorrespondeToRouteId(generateLabelForRoute(routeIdObj));
                }
            }
            setData(updateData);
        };
    
        fetchData();
    },[])
   return(
    <div className="row vehicle-info">
        <div className="col-sm-12">
            {Object.keys(data).map(columnKey=> {
                return <VehicleInfoItem key={columnKey} label={columnKey} value={data[columnKey]} routeNameCorrespondeToRouteId={routeNameCorrespondeToRouteId}/>
            })}
        </div>
    </div>
   )
};