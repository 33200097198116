const validateBSDDevice = (formState) => {
  let form = {};
  if (!formState.deviceId || !formState.category) {
    form._error = "Required field missing";
    form.invalid = true;
  } else {
    form.invalid = false;
  }
  return form;
};
export default validateBSDDevice;
