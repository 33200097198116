const mappedRoutesData = (data) => {
  return data.map((element) => {
    if (element.spf) {
      element["serviceTag"] = [...element.special_features, ...element.spf];
    } else {
      element["serviceTag"] = [...element.special_features];
    }
    element["serviceTag"] = element["serviceTag"].join(",");
    return element;
  });
};

const generateLabelForRoute = (row)=>{
  let label = row.route_name;
  let serviceTag = row.special_features;
  if (row.spf) {
    serviceTag.push(...row.spf);
  }
  if(serviceTag.length){
    label = label + "(" + serviceTag.join(",") + ")";
  }
  label = `${label} ( ${row.first_stop_name} -> ${row.last_stop_name} )`;
  return label
}

const generateRouteIdOptions = (routes) => {
  return routes.map((data) => {
    return {
      label:generateLabelForRoute(data),
      value: data.route_id,
    };
  });
};

export { mappedRoutesData, generateRouteIdOptions,generateLabelForRoute };
